// import { sanitize } from 'isomorphic-dompurify'
import { FC, useState } from 'react'
import AnswerChartModal from '../Modals/AnswerChartModal'
import AnswerChart from './AnswerChart'
import AnswerTable from './AnswerTable'
import FeedbackControl from './FeedbackControl'
import { makeStyles } from '../../core/utils/theme'
import HTMLChart from './HtmlChart'
import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import ContentModal from '../Modals/ContentModal'
import { IAnswerResponse } from '../../core/types/code_service/IAnswerResponse'

// Icon
// import ViewIcon from '../../../assets/Svgs/action/view_24 px.svg';
// import SplitScreenIcon from '../../../assets/Svgs/action/splitscreen_add_24_px.svg';

const useStyles = makeStyles()(() => ({
  root: {
    height: '100%',
    padding: '1.5rem 1.5rem 0',
    width: '100%',
    // flexGrow: 1,
    fontFamily: 'Inconsolata, monospace',
    overflow:'auto'
  },
  answerText: {
    textAlign: 'left',
    fontSize: '1.2rem',
  },
  insightRecommendationSection: {

  },
  dataTableContainer:{
    width:'98%',
    margin:'0',
    overflow:'auto'
  },
  feedbackControl: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1.5rem',
    marginTop: "10px",
    marginBottom: "10px",
  },
  buttonContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    alignSelf: 'center',
    textTransform: 'uppercase',
    padding: '0.5rem 1.5rem',
  },
  tableContainer: {
    display: 'block',
    maxWidth: '100%',
  },
  table: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 500,
  },
  icon: {
    width: 24,
    height: 24,
  }
}))

interface IProps {
  answer: IAnswerResponse | null
  isDashboard?: boolean
}

enum ModalType {
  CHART,
  DATA
}

const AnswerSection: FC<IProps> = observer(({answer, isDashboard = false }) => {
  if (!answer)
    return <></>
    
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClick = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const { classes } = useStyles()
  // const ansDiv = useRef<HTMLDivElement>(null)
  const [modalType] = useState<ModalType>(ModalType.CHART);
  const app = useApp()
  const [isEditGraphModalOpen, setIsEditGraphModalOpen] = useState<boolean>(false)
  const [editGraphRequest, setEditGraphRequest] = useState<string>("")

  const getContent = () => {
    if (modalType === ModalType.CHART) {
      if (answer.chart_html) return <HTMLChart html={answer.chart_html} isDashboard={isDashboard} />
      else return <AnswerChart fullWidth={true} data={answer.chart_data} onClick={handleClick} isDashboard={isDashboard}/>
    }

    if (modalType === ModalType.DATA) {
      return <AnswerTable containerStyle={classes.tableContainer} tableStyle={classes.table} data={answer.data} />
    }
  }

  const editGraph = () => {
    setIsEditGraphModalOpen(true)
  }
  
  return (
    <Box className={classes.root}>
      <ContentModal open={isEditGraphModalOpen} title="Hello" onClose={()=>setIsEditGraphModalOpen(false)}>
        <Box className='flex flex-col'>
          <Box className="mb-5">Ask Cubie to make a change to the graph...</Box>
          <textarea className='border border-black'
            onChange={(e)=>{setEditGraphRequest(e.target.value)}}
            onKeyUp={(e)=>{
              if (e.key == "Enter" && e.ctrlKey)
                app.editVisualization(editGraphRequest)
            }}>
          </textarea>
          <Box className='flex justify-end mt-2'>
            <Button variant='outlined' onClick={() => app.editVisualization(editGraphRequest)}>Submit</Button>
          </Box>
        </Box>
      </ContentModal>

      <Box className={classes.insightRecommendationSection}>
        {answer.insight && 
          <Box className="pb-2">
            <b>Insight</b>: {answer.insight}
          </Box>
          
        } 
        {answer.recommendation && 
          <Box className="pb-2">
            <b>Recommendation</b>: {answer.recommendation}
          </Box>
        } 
        {answer.answer && 
          <Box className="pb-2">
            <b>Data</b>: {answer.answer}
          </Box>
        }
      </Box>

      {answer.chart_html &&
        <>
        <Box className="relative">
          <Box className="bottom-0 right-0 absolute mr-2 cursor-pointer" onClick={editGraph}>Edit graph</Box>
          <HTMLChart html={answer.chart_html} isDashboard={isDashboard} />
        </Box>
        <AnswerChartModal open={isOpen} onClose={handleClose} title="Answer">
          {getContent()}
        </AnswerChartModal>
        </>
      }
  
      {answer.data &&
        <Box className={classes.dataTableContainer}>
          <AnswerTable data={answer.data} />
        </Box>
      }

      {!isDashboard &&
        <Box className="flex flex-row items-center my-3">
          <Box className={classes.feedbackControl}>
            <span className="text-s">How did I do?</span>
            <FeedbackControl />
          </Box>
        </Box>
      }
    </Box>
  )
})

export default AnswerSection
