import { Box } from '@mui/material'
import { sanitize } from 'isomorphic-dompurify'
import { observer } from 'mobx-react'
import { FC, useEffect, useRef } from 'react'
import { thoughtsProfile } from '../../core/config/dompurify'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import { to_html } from '../../core/utils/main'
import { LoadingSpinner } from '../Misc/Loader'
import NewWindow from 'react-new-window'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    height:'100%',
    width: '100%',
    marginBottom: '0rem',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem',
    background: '#88888833',
    '& > div': {
      padding: '0.25rem',
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
  thoughts: {
    minHeight: '4rem',
    maxHeight: '100%',
    overflow: 'auto',
    overflowWrap: 'anywhere',
    padding: '0 0.75rem 0.75rem',
  },
}))

export const ThoughtsInMain: FC<IProps> = observer(() => {
  const { classes, cx } = useStyles()
  const app = useApp()

  useEffect(() => {
  }, [app.thoughtsText])

  return (
    <Box className={cx(classes.root, 'action-log')}>
        <Box className={classes.title}>
          <Box>Cubie's Thoughts <LoadingSpinner className="inline h-[20px] ml-[10px]"/></Box>
      </Box>
      <Box className={classes.thoughts}
        dangerouslySetInnerHTML={{__html: sanitize(to_html(app.thoughtsText), thoughtsProfile),}}
      />
    </Box>
  )
})

interface IProps {
}
export const ThoughtsInNewWindow: FC<IProps> = observer(() => {
  const { classes, cx } = useStyles()

  const app = useApp()
  // const thoughtsDiv = useRef<HTMLDivElement>(null)
  const newWindowRef = useRef(null)

  if (app.thoughtsText?.length == 0)
    return <></>
  return (
      <NewWindow
        ref={newWindowRef}
        features={{
          left: '0',
          top: '0',
          width: 1000,
          height: 600,
        }}
        onUnload={() => app.closeThoughtsOpenedInNewWindow()}
        title="Cubie's Thoughts"
        name="Cubie's Thoughts"
      >
        <Box className={cx(classes.root, 'action-log')}>
          <Box className={classes.title}>
            <Box>Cubie's Thoughts</Box>
          </Box>

          <Box
            // ref={thoughtsDiv}
            dangerouslySetInnerHTML={{__html: sanitize(to_html(app.thoughtsText), thoughtsProfile),}}
            className={classes.thoughts}
          />
        </Box>
      </NewWindow>
  )
})
