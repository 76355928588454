import { FC, useEffect, useState } from 'react'
import PageLayout from '../Layouts/PageLayout'
import  theme, { makeStyles } from '../../core/utils/theme'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import useUser from '../../hooks/useUser'
import useSettings from '../../hooks/useSettings'
import { useNavigate } from 'react-router-dom'
import { isEndUserView } from '../../core/utils/main'
import { IDashboardItem } from '../../core/types/code_service/IDashboardItem'
import AnswerSection from '../AskCubie/AnswerSection'
// import HTMLChart from '../AskCubie/HtmlChart'

import { Box, Button, Grid, MenuItem, Select, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { TourType } from '../../core/types/source_service/IGuideTour'
import LeftSidebar from '../LeftSidebar/LeftSidebar'
// import Tour, { dashboardHowToTourSteps } from '../Misc/Tour'

import ClearIcon from '@mui/icons-material/Clear'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
// import ReplayIcon from '@mui/icons-material/Replay'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { LoadingSpinner } from '../Misc/Loader'

const useStyles = makeStyles()((theme) => ({
  root: {
    display:'flex',
    height:'100%'
  },
  dashboardContainer: {
    width: '100%',
    height: '100%',
    padding: '0 20px',
    overflow: 'auto'
  },
  dashboardRoot:{
    alignItems: 'center',
    overflowY: 'auto',
    width:'100%'
  },
  dashboardTitle: {
    margin:'10px 0 5px',
    textAlign:'center'
  },
  dashboardTopControls: {
    height:'30px',
    display:'flex',
    justifyContent:'end',
    alignContent:'center',
  },
  dashboardGridWrapper: {
    width: '650px',
    [theme.breakpoints.up(1650)]: {
      width: '1300px',
    },
    [theme.breakpoints.up(2330)]: {
      width: '1980px',
    },
    display:'flex',
    justifyContent:'center',
  },
  gridItemContainer: {
    margin:'20px',
    overflow:'hidden',
    position:'relative',
    border:'1px solid black',
    width:'608px',
    // height:'430px',
    height:'608px',
    // minHeight:'430px',
    // maxHeight:'608px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between'
  },
  dashboardItemControls: {
    width:'100%',
    height:'26px',
    position:'relative',
    display:'flex',
    justifyContent:'space-between',
    background:'rgba(0,0,0,0.05)',
    borderBottom:'1px solid grey',
    flex:'0 0'
  },
  dashboardItemControl: {
    height:'16px',
    cursor:'pointer',
    opacity:'50%',
    '&:hover': {
      opacity:'100%'
    }
  },
  answerContainer: {
    position:'relative',
    display:'flex',
    flexDirection:'column',
    width:'100%',
    height:'564px',
    overflow:'hidden',
    zIndex:'0',
  },
  answerWrapper: {
    overflowY:'auto',
    textOverflow:'ellipsis',
    position:'relative',
    scale:'67%',
    width:'150%',
    minHeight:'150%',
    // paddingY:'2px',
    left:'-150px',
    top:'-142px',
    zIndex: '1',
    paddingTop:'5px',
    flex: '1 0',
  },
  dashboardItemLowerControls: {
    flex:'0 0',
    background:'#eee',
    backgroundOpacity:'100%',
    height:'16px',
    width:'100%',
    padding:'0px 2px 0 2px',
    display:'flex',
    justifyContent:'space-between',
  },
  newItemBox: {
    margin:'20px',
    width:'608px',
    height:'408px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    border:'1px solid #999',
    color: '#999',
    cursor: 'pointer',
    '& .hiddenChild': {
      // visibility:'hidden'
    },
    '&:hover': {
      color: 'black',
      border:'1px solid black',
      '& .hiddenChild': {
        // visibility:'visible'
      }
    }
  },
  newItemBoxText: {

  },
  slideOverlayContainer: {
    height: '100%',

  },
  slideOverlayScreen: {
    position: 'absolute',
    top:'0',
    bottom:'0',
    right:'0',
    left:'0',
    background:'white',
    zIndex:'5',
    alignItems:'center',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignContent:'center'
  },
  slideContainer: {
    width:'90%',
    maxWidth:'1400px',
    aspectRatio:'16/10',
    margin: 'auto auto',
    background:'#def',
    border: '1px solid black',
    position:'relative',
    display:'flex',
    alignContent:'center',
  },
  slideAnswerContainer: {
    width: '90%',
    maxWidth:'1200px',
    aspectRatio: '16/10',
    margin: 'auto auto',
    background: '#def',
    border: '1px solid black',
  }
}))

const timestr = (s: string) => {
  if (!s)
    return ''
  return s.slice(0,19).replace('T', '  ')
}

interface IProps {
  openOverlay: (items: IDashboardItem[], i: number) => void
}

const DashboardItems: FC<IProps> = observer(({openOverlay}) => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()
  const dashboardItems = app.dashboardItems.concat(app.tempDashboardItems)
  // const dashboardItems = app.dashboardItems.concat(app.dashboardItems).concat(app.dashboardItems).concat(app.dashboardItems).concat(app.dashboardItems).concat(app.tempDashboardItems)
  const navigate = useNavigate()

  useEffect(() => {
    if (!window.location.pathname.includes('dashboard'))
      navigate(isEndUserView() ? `/dashboard?cakeId=${app.cakeId}` : `/dashboard` )
  }, [app.cakeId])

  // useEffect(() => {
  //   // if (isEndUserView() && !user.isAuthenticated() && !settings.hasSeenDashboardHowTo)
  //   //   if (!(settings.tourType == TourType.DASHBOARD_HOW_TO))
  //   //     settings.startTour(TourType.DASHBOARD_HOW_TO, dashboardHowToTourSteps)
  // }, [settings.tourType, settings.hasSeenDashboardHowTo])

  // const rerunAll = async (e: MouseEvent<HTMLElement>) => {
  //   app.setIsDashboardLoading(true)
  //   const element = e.target as HTMLElement
  //   element.innerText = 'Running...'
  //   await app.runAllDashboardItems(app.cakeId, element)
  //   element.innerText = 'Done'
  //   setTimeout(() => {
  //     element.innerText = 'Re-run ALL analyses'
  //   }, 3000)
  // }

  // const rerun = async (e: MouseEvent<Element>, query_id: string) => {
  //   const element = e.target as HTMLElement
  //   element.innerText='Running...'  
  //   const r = await app.runDashboardItem(app.cakeId, query_id, element)
  //   console.log('r', r)
  //   app.loadDashboardItems(app.cakeId)
  // }
  
  const handleDeleteDashboardItem = async (query_id: string) => {
    const r = await app.deleteDashboardItem(app.cakeId, query_id)
    console.log('r',r)
    app.loadDashboardItems(app.cakeId)
  }

  const handleReorder = (currentPosition: number, newPosition: number) => {
    if (newPosition < 1 || newPosition > app.dashboardItems.length || currentPosition === newPosition) {
      return;
    }
    const updatedItems = [...app.dashboardItems];
    const [movedItem] = updatedItems.splice(currentPosition - 1, 1)
    updatedItems.splice(newPosition - 1, 0, movedItem)
    app.setDashboardItems(updatedItems)
    app.saveDashboardOrder()
  }

  const newDashboardItemClick = () => {
    console.log("newDBItemClick")
    console.log(app.cakeId, user.isAuthenticated(), settings.hasSeenDashboardHowTo)
    app.prepareForQuestion(false)
    app.setInput('')
    if (isEndUserView() && !user.isAuthenticated() && settings.tourType == TourType.DASHBOARD_HOW_TO) {
      settings.setTourStepIndex(1)
    }
    navigate(isEndUserView() ? `/ask?cakeId=${app.cakeId}` : '/ask' )
  }

  const handleExpandItem = (i: number) => {
    openOverlay(dashboardItems, i)
  }
  
  return (
    <Box className={classes.dashboardRoot}>
      {// Title Bar
        app.cakeId && 
          <Box className={classes.dashboardTitle}>
            {app.isDashboardLoading && <LoadingSpinner className="inline h-[20px] mx-[10px]"/> }
            <span className="mt-[5px] h-[100%] inline">
              <Typography className="inline-block" variant="h6">
                <span
                  className={(user.isAuthenticated() && !isEndUserView()) ? 'cursor-pointer' : ''}
                  onClick={()=>{if (user.isAuthenticated() && !isEndUserView()) settings.setIsDatacakesModalOpen(true)}}
                >
                  {app.cakeName ? app.cakeName : app.cakeId}
                </span>
              </Typography>
            </span>
            {app.isDashboardLoading && <LoadingSpinner className="inline h-[20px] mx-[10px]"/> }
          </Box>
      }

      { // Top Controls
       !app.isDashboardLoading && app.dashboardItems && app.dashboardItems.length > 0 &&
        <Box className={classes.dashboardTopControls}>
          <>
            <Button variant='outlined' className="mt-2 cursor-pointer mr-4" id="dashboardShareButton" onClick={(e)=>{e.stopPropagation(); settings.setIsShareModalOpen(true)}}>Share Dashboard</Button>
            {/* <Button variant='outlined' className="mt-2 cursor-pointer" onClick={(e) => rerunAll(e)}>Re-run ALL analyses</Button> */}
          </>
        </Box>
      }

      { // Main Dashboard Content
        <Box className="flex justify-center">
          <Box className={classes.dashboardGridWrapper}>
            <Grid container>
              {dashboardItems.map((item: IDashboardItem, i: number) => (

                // Dashboard Item
                <Grid item className={classes.gridItemContainer} key={item.query_id}>
                  <Box className={classes.dashboardItemControls}>
                    <Box className="p-0 m-0">
                      {(user.isInsider() || !isEndUserView() || app.cakeId == 'nhanes_fd') &&
                        <Tooltip title="Remove from Dashboard">
                          <ClearIcon
                            className={classes.dashboardItemControl}
                            onClick={() => handleDeleteDashboardItem(item.query_id)}
                          />
                        </Tooltip>
                      }
                      <Box className="inline relative top-[1px] left-[4px]">{item.answer?.title ? item.answer.title : ''}</Box>
                    </Box>
                    <Box className="flex h-full">
                      <Box className="flex text-xs">
                        <Select
                          className="appearance-none bg-white"
                          value={i + 1}
                          onChange={(e) => handleReorder(i + 1, e.target.value as number)}
                        >
                          {dashboardItems.map((_, index) => (
                            <MenuItem className="bg-white hover:bg-blue-300 aria-selected:bg-blue-100 aria-selected:hover:bg-blue-300" key={index} value={index + 1}>{index + 1}</MenuItem>
                          ))}
                        </Select>
                      </Box>

                      {/* <Tooltip title="Re-run Analysis">
                        <ReplayIcon
                          className={classes.dashboardItemControl}
                          onClick={(e) => rerun(e, item.query_id)}
                        />
                      </Tooltip> */}
                      
                      <Tooltip title="Full screen">
                        <Box className="height:100%">
                        <OpenInFullIcon
                          className={classes.dashboardItemControl}
                          onClick={() => handleExpandItem(i)}
                        />
                        </Box>
                      </Tooltip>
                      
                    </Box>
                  </Box>
                  <Box className="flex flex-col flex-grow"
                    id={(settings.tourType == TourType.DASHBOARD_HOW_TO) && (i == dashboardItems.length-1)
                      ? 'dashboardViewNewItem'
                      : `dashboardItem_${i}`
                    }
                  >
                    <Box className={classes.answerContainer}>
                      <Box className={classes.answerWrapper}>
                        {
                        // item.answer?.chart_html ?  
                        // <HTMLChart answerChartHtml={item.answer.chart_html} isDashboard={true}/>
                        // :
                        <AnswerSection
                          answer={item.answer} 
                          isDashboard={true}/>
                        }
                      </Box>
                    </Box>
                  </Box>
                  {item.saved_at && 
                  <Box className={classes.dashboardItemLowerControls}>
                    <Box className="text-black text-xs italic h-full flex flex-column justify-end" >
                      Last run {timestr(item.saved_at)}
                    </Box>
                    {/* <Box  className="float-right cursor-pointer text-xs underline ">
                    Re-run analysis
                    </Box> */}
                  </Box>
                  }
                </Grid>
              ))}

              { // New Dashboard Item
                <Box className="relative">
                  <Grid item id='dashboardAddNewItem' className={classes.newItemBox} onClick={newDashboardItemClick}>
                    <div style={{visibility:'hidden'}}>New Dashboard Item</div>
                    <div><AddIcon fontSize='large'/></div>
                    <div className="hiddenChild">New Dashboard Item</div>
                  </Grid>
                </Box>
              } 
            </Grid>
          </Box>
        </Box>
      }
    </Box>
  )
})
interface ISlideOverlay {
  overlayItems: IDashboardItem[] | null
  currentItemIndex: number
  closeOverlay: ()=>void
  setCurrentItemIndex: (i:number)=>void
}

const SlideOverlay: FC<ISlideOverlay> = ({overlayItems, currentItemIndex, closeOverlay, setCurrentItemIndex}) => {
  const { classes } = useStyles()
  const dbItem: IDashboardItem | null = overlayItems ? overlayItems[currentItemIndex] : null
  if (!dbItem)
    return null

  const nextIndex:number = overlayItems ? (currentItemIndex + 1) % overlayItems.length : 0
  const prevIndex:number = overlayItems ? (currentItemIndex - 1 + overlayItems.length) % overlayItems.length : 0

  return (
    <Box className={classes.slideOverlayScreen}>
      <Box className={classes.slideContainer}>
        <Box className="absolute top-0 w-full flex justify-center m-4">{dbItem?.answer?.title}</Box>
        <CloseFullscreenIcon className="absolute top-2 right-2 cursor-pointer z-[2]" onClick={() => closeOverlay()} />
        {overlayItems && overlayItems.length > 1 && (
          <ArrowBackIosIcon className='absolute left-2 top-[48%] cursor-pointer' onClick={()=>setCurrentItemIndex(prevIndex)} />
        )}
        {overlayItems && overlayItems.length > 1 && (
          <ArrowForwardIosIcon className='absolute right-2 top-[48%] cursor-pointer' onClick={()=>setCurrentItemIndex(nextIndex)} />
        )}
        <Box className={classes.slideAnswerContainer}>
          <AnswerSection
              answer={dbItem.answer} 
              isDashboard={true}/>

          {/* {dbItem.answer.chart_html && <HTMLChart answerChartHtml={dbItem.answer.chart_html} isDashboard={true} />} */}
        </Box>
      </Box>
    </Box>
  )
}

const Dashboard: FC = observer(() => {
  const { classes } = useStyles()
  const [overlayItems, setOverlayItems] = useState<IDashboardItem[]|null>(null)
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const openOverlay = (dbItems: IDashboardItem[], index: number) => {
    setOverlayItems(dbItems)
    setCurrentItemIndex(index)
  } 

  const closeOverlay = () => {
    setOverlayItems(null)
  }

  return (
    <PageLayout>
      <Box className={classes.root}>
        {!isMobile && <LeftSidebar />}
        <Box className={classes.slideOverlayContainer}>
          {overlayItems && <SlideOverlay overlayItems={overlayItems} currentItemIndex={currentItemIndex} closeOverlay={closeOverlay} setCurrentItemIndex={setCurrentItemIndex}/>}
        </Box>
        <Box className={classes.dashboardContainer}>
          <DashboardItems openOverlay={openOverlay} />
        </Box>
      </Box>
      {/* <Tour/>  */}
    </PageLayout>
  )
})

export default Dashboard
