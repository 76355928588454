import { Rating } from '@mui/material'
import { observer } from 'mobx-react'
import { ChangeEvent, FC, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import Button from '../Misc/Button'


const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  commentButton: {
    fontSize:'xs'
  },
  icon: {
    height: 24,
    width: 24,
  },
  tooltip: {
    zIndex: 10,
  },
  tellusmoreContainer: {
    margin: '0.25rem',
    display: 'flex',
    width: '100%',
    maxWidth: '300px',
    flexDirection: 'column',
  },
  tellusmoreText: {
    marginBottom: '0.5rem',
    fontWeight: 'bold',
  },
  tellusmoreInput: {
    marginBottom: '0.75rem',
  //borderRadius: '0.75rem',
    padding: '0.5rem',
    color: theme.palette.text.primary,
  },
}))

const FeedbackControl: FC = observer(() => {
  const { classes } = useStyles()
  const app = useApp()
  const [value, setValue] = useState<number>(0)
  const [commentVisibility, setCommentVisibility] = useState<string>('hidden')
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const [isFeedbackTooltipOpen, setIsFeedbackTooltipOpen] = useState<boolean>(false)

  const handleSubmit = () => {
    if (feedbackMessage.length > 0)
      app.flagAnswer(value, feedbackMessage)
    setIsFeedbackTooltipOpen(false)
    setFeedbackMessage('')
  }

  const handleFeedbackMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    const newValue = e.target.value
    setFeedbackMessage(newValue)
  }

  return (
    <div className={classes.root}>
        <Rating
          name="simple-controlled"
          value={value}
          onChange={(_, newValue) => {
            if (newValue) {
              setValue(newValue)
              setCommentVisibility('visible')
              app.flagAnswer(newValue, '')
            }
          }}
        />
      <span
        data-tooltip-id="feedback-tooltip"
        className={`${commentVisibility} text-s cursor-pointer pl-5`}
        // onClick={()=>setIsFeedbackTooltipOpen(true)}
      >
        Tell me more?
      </span>

      <Tooltip
        id="feedback-tooltip"
        openOnClick={true}
        clickable={true}
        opacity={1}
        globalCloseEvents={{
          escape: true,
          resize: true,
          scroll: true,
          clickOutsideAnchor: true
        }}
        className={classes.tooltip}
        isOpen={isFeedbackTooltipOpen}
        setIsOpen={setIsFeedbackTooltipOpen}
        >
        <div className={classes.tellusmoreContainer}>
          <div className={classes.tellusmoreText}>How can I improve?<br/>What did I do well?</div>
          <textarea
            className={classes.tellusmoreInput}
            rows={4}
            onChange={handleFeedbackMessageChange}
            onKeyUp={(e) => {
              if (e.key == 'Enter')
                handleSubmit()
            }}
          ></textarea>
          <Button onClick={()=>handleSubmit()}>Submit</Button>
        </div>
      </Tooltip>
    </div>
  )
})

export default FeedbackControl
