import { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'

interface IProp {
    text: string
}

const LoadingSplashScreen: FC<IProp> = (input) => {
    const [dots, setDots] = useState<string>('')

    useEffect(() => {
        doDots(0)
    }, [])

    const doDots = async (i:number) => {
        setTimeout(() => {
          setDots('.'.repeat(i))
          doDots((i+1)%4)
        }, 250)
      }
            
    return (
        <Box className="flex flex-col h-full w-full justify-middle justify-center">
            <div className="text-center">
            <span className="invisible">{dots}</span>
            <span className="">{input.text}</span>
            <span className="">{dots}</span>
            </div>
        </Box>
    )
}

export default LoadingSplashScreen