import { Box, Button, /*Grid, */ Typography } from '@mui/material'
import { sanitize } from 'isomorphic-dompurify'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { IDataObject } from '../../core/types/code_service/IDataObject'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import { isEndUserView } from '../../core/utils/main'
import useUser from '../../hooks/useUser'
import useSettings from '../../hooks/useSettings'
import DataViewModal from '../Modals/DataViewModal'
import DataObjectHeadModal from '../Modals/DataObjectHeadModal'
import { DATACAKES_CODE_SERVICE_URL } from '../../core/config/main'
import CakeInstructionsModal from '../Misc/CakeInstructions'
import DescriptionsModal from '../Modals/DescriptionsModal'

const useStyles = makeStyles()((theme) => ({
  loader: {
    width: '100px',
    display: 'block',
  },
  tableSummary: {
    background: 'transparent',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  sourceRoot: {
    // paddingLeft: '0.5rem',
    paddingTop: '0.5rem',
    zIndex: '10',
  },
  sourceName: {
    paddingLeft: '0.5rem',
    display: 'block',
    whiteSpace: 'nowrap', // truncate text
    // overflow: 'hidden', // // truncate text
    textOverflow: 'ellipsis',
    maxWidth: '100%', // or set a specific width according to your layout
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    //borderRadius: 4,
    },
  },
  sourceHeaderRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '0.5rem',
  },
  infoIcon: {
    display: 'flex',
    height: '1.25rem',
    width: '1.25rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  //borderRadius: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '0.875rem',
    lineHeight: '0.75rem',
    minWidth: '0%',
    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent:'end'
  },
  bottomContainer: {
    paddingTop: '20px',
    display: 'flex',
    flex: 0,
    flexDirection: 'column'
  },
}))

const DataObjectEntries: FC = observer(() => {
  const { classes } = useStyles()
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  let srcTableMap = Object()

  if (!app.cakeId) {
    console.log("No cake ID")
    return
  }
  app.coreDataObjects.forEach((src) => {
    if (!srcTableMap[src.source])
      srcTableMap[src.source] = [src]
    else
      srcTableMap[src.source].push(src)
  })

  return (
    <Box
      sx={{
        flexDirection: 'column',
        height:'fit-content'
      }}
    >
      <Box className={classes.contentContainer}>
        {/* {app.isInitializing && <Loader className={classes.loader} />} */}

        {Object.keys(srcTableMap).map((srcType: string, i: number) => {
          const srcs = srcTableMap[srcType]
          return (
            <Box key={i} sx={{ marginTop: '12px' }}>
              <Typography variant='body2' fontFamily={'helvetica'} fontWeight={'bold'}>{srcType === 'duckdb' ? 'csv' : srcType}</Typography>
              {srcs.map((s: IDataObject) => {
                console.log('Table', s.name)
                return <DataObjectEntry key={s.name} source={s} />
              })}
            </Box>
          )
          })}
      </Box>
      {user.isAuthenticated() && !isEndUserView() && app.cakeId && (
        <Box className={classes.bottomContainer}>
            <CakeInstructionsModal />
            <Button variant='outlined' onClick={() => settings.setIsCakeInstructionsModalOpen(true)} className='uppercase text-xs'>
              Edit Instructions
            </Button>
            <div style={{height:'3px'}}></div>
            <DescriptionsModal />
            <Button variant='outlined' onClick={() => settings.setIsDescriptionsModalOpen(true)} className='uppercase text-xs'>
              Edit Descriptions
            </Button>
            <div style={{height:'3px'}}></div>
            <Button variant='outlined' onClick={() => settings.setIsCreateDatacakeModalOpen(true, true)} className='uppercase text-xs'>
              Alter Datacake Recipe
            </Button>
        </Box>
      )}
    </Box>
  )
})
export default DataObjectEntries

interface IDataObjectEntryContent {
  isSessionSource?: boolean
  source: IDataObject
  className?: string
}
const DataObjectEntryContent: FC<IDataObjectEntryContent> = ({
  source,
  className,
  isSessionSource = false,
}) => {
  const { classes, cx } = useStyles()
  
  return (
      <Box className={cx(classes.tableSummary, className)} style={{position:'relative', width:'280px'}}>
        <Box>
          <b>Name:</b> {sanitize(source.name)}
        </Box>

        <Box>
          <b>{!isSessionSource ? 'Description' : 'Generating Question'}:</b> {sanitize(!isSessionSource ? source.description : source.q || '')}
        </Box>

        <Box>
          <b>Columns:</b> {source.columns?.map((column: any) => column.name).join(', ')}
        </Box>
            
        {source.num_rows && (
          <Box>
            <b># Rows:</b> {Number(source.num_rows)}
          </Box>
        )}
      </Box>
  )
}

interface IDataObjectEntry {
  source: IDataObject
  className?: string
  isSessionSource?: boolean,
  isModelsSource?: boolean
}
export const DataObjectEntry: FC<IDataObjectEntry> = ({ source, className, isSessionSource = false, isModelsSource = false }) => {
  const { classes, cx } = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const user = useUser()
  const app = useApp()
  
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const hasHead = source.sample && source.sample?.columns != null

  async function download_as_csv(e: MouseEvent, var_name: string) {
    e.stopPropagation()
    window.location = `${DATACAKES_CODE_SERVICE_URL}/download-data?cake_id=${app.cakeId}&var_name=${var_name}` as string & Location
    const elt = e.target as HTMLElement
    document.body.style.cursor = 'progress'
    elt.style.cursor = 'progress'
    setTimeout(()=>{
      elt.style.cursor = 'pointer'
      document.body.style.cursor = 'auto'
    }, 2000)
  }

  return (
    
    <Box className={cx(classes.sourceRoot, className)}>
      <Box
        data-tooltip-id={source.name}
        className={classes.sourceName}
        sx={[
          hasHead && {
            cursor: 'pointer',
          },
        ]}
        onClick={hasHead ? handleClick : undefined}
      >
        {user.isInsider() ? <span onClick={(e)=>{download_as_csv(e, source.name)}}>#</span> : '#'} {source.name}
      </Box>
      {hasHead && (
        <>
          {!isModelsSource && <DataObjectHeadModal
            head={source.sample}
            limit={isEndUserView() ? 10 : -1} // -1 is used to not enforce the limit condition
            title={`Sample (limited rows) of ${source.name}`}
            open={isModalOpen}
            onClose={handleModalClose}
          />}
          {isModelsSource && <DataViewModal
            head={source.sample}
            title={`Sample (limited rows) of ${source.name}`}
            open={isModalOpen}
            onClose={handleModalClose}
          />}
        </>
      )}
      <Tooltip
        id={source.name}
      >
        <DataObjectEntryContent isSessionSource={isSessionSource} source={source} />
      </Tooltip>
    </Box>
  )
}

