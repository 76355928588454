import { sanitize } from 'isomorphic-dompurify'
import { FC } from 'react'
import { makeStyles } from '../../core/utils/theme'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',

    '& th, & td': {
      border: `1px solid ${theme.palette.text.disabled}`,
    },

    '& th': {
      background: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    },

    '& td': {
      color: theme.palette.text.primary,
    },

    '& caption': {
      color: theme.palette.text.primary,
    },
  },
}))

interface IProps {
  title?: string
  columns: string[]
  data: (string | number)[][],
  rowLimit?: number
}
const DataTable: FC<IProps> = ({ title, columns, data, rowLimit = -1 }) => {
  const { classes } = useStyles()

  console.log('Row limit', rowLimit);

  return (
    <table className={classes.root}>
      {title && <caption>{title}</caption>}
      <thead>
        <tr>
          {columns.map((key: string, index: number) => (
            <th key={index}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any, index: number) => {
          if (rowLimit > -1) {
            if (index < rowLimit) {
              return (
                <tr key={index}>
                  {row.map((value: string | number, index2: number) => (
                    <td key={index2}>{sanitize(String(value))}</td>
                  ))}
                </tr>
              )
            }
          } else {
            return (
              <tr key={index}>
                {row.map((value: string | number, index2: number) => (
                  <td key={index2}>{sanitize(String(value))}</td>
                ))}
              </tr>
            )
          }
        })}
      </tbody>
    </table>
  )
}
export default DataTable
