import { useEffect } from 'react'
import useSettings from '../../hooks/useSettings'
import PageLayout from '../Layouts/PageLayout'
import { hasTourFlagInUrl } from '../../core/utils/main'
import { observer } from 'mobx-react'
import LeftSidebar from '../LeftSidebar/LeftSidebar'
import { Box, useMediaQuery } from '@mui/material'
import theme, { makeStyles } from '../../core/utils/theme'
import Cubie from './Cubie'

const useStyles = makeStyles()(() => ({
  root: {
    display:'flex',
    height:'100%',
    overflow:'hidden'
  }
}))

const AskCubie = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const tour: boolean = hasTourFlagInUrl()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    // Show guided tour if we have tour value in url
    if (tour) {
      console.log('Open guide tour modal');
      settings.setIsGuideTourModalOpen(true);
    }
  }, [tour])

  return (
    <PageLayout>
      <Box className={classes.root}>
        {!isMobile && <LeftSidebar />}
        <Cubie />
      </Box>
    </PageLayout>
  )
})

export default AskCubie
