import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { FC} from 'react'
import ContentModal from '../Modals/ContentModal'
import useSettings from '../../hooks/useSettings'
import Datacakes from './Datacakes'

const DatacakesModal: FC = observer(() => {
  const settings = useSettings()

  const modalStyles: ReactModal.Styles = {
    overlay: {
      backdropFilter: 'blur(10px)',
      zIndex: 10,
      background: 'transparent',
      alignItems: 'center',
      paddingLeft: '20px',
      paddingRight: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      WebkitBackdropFilter: 'blur(10px)',
    },
    content: {
      color: 'var(--qbd-color-a1)',
      padding: 0,
      width: '600px',
      position: 'static',
      border: '2px solid black',
      backgroundColor: 'white',
    },
  }

  const handleClose = () => {
    settings.setIsDatacakesModalOpen(false)
  }

  return (
    <ContentModal
      style={modalStyles}
      open={settings.isDatacakesModalOpen}
      title={'Your Datacakes'}
      showCloseButton={true}
      onClose={handleClose}
    >
      <Box className="grow">
        <Box className={'flex flex-col grow items-center '}><Typography variant="h6" className="mb-4">Your Datacakes</Typography></Box>
        <Datacakes />
      </Box>

    </ContentModal>
  )
})

export default DatacakesModal
