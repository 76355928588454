import { FC, ReactNode, useEffect, useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import Modal from 'react-modal'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { success } from '../../core/services/alerts';
import useSettings from '../../hooks/useSettings';

// Icon
import COPY from '../../assets/Svgs/content/copy_24px.svg';
import CLOSE from '../../assets/Svgs/navigation/close_24px.svg'
import useApp from '../../hooks/useApp';

interface IProps {
  open: boolean
  onClose: () => void,
  children?: ReactNode
}
const ShareModal: FC<IProps> = ({ open = false, onClose }) => {

  const settings = useSettings()
  const app = useApp()
  const [url, setUrl] = useState('');
  const [isDashboard, setIsDashboard] = useState<boolean>(false)

  useEffect(() => {
    if (!window.location.href.includes('ask'))
      setIsDashboard(true)
    if (app.cakeId) {
      setUrl(`${window.location.origin}/${isDashboard?'dashboard':'ask'}?cakeId=${app.cakeId}`)
    }
  }, [app.cakeId, isDashboard])

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={
        'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10'
      }
      className={`static max-h-full max-w-full md:max-w-[600px] min-h-[230px] bg-white pl-6 pb-4 border border-black mx-4`}
    >
      <Box pt={1} className="flex flex-col h-full">
        <Box className="flex justify-end ">
          <Button className="" onClick={() => settings.setIsShareModalOpen(false)}>
            <img src={CLOSE} />
          </Button>
        </Box>
        <Box className="flex-1 mr-6">
          <Typography variant='h5' className='mb-4'>Share this {isDashboard? 'Dashboard' : 'Datacake'} with others!</Typography>
          <Typography variant="body2" color={'#5B6B6F'} className='leading-5 mb-4'>
            The URL for this {isDashboard? 'dashboard' : 'datacake'} has been copied. Send it to whoever you think would find it useful.<br/><br/>
            {!isDashboard && <><span>Build a dashboard if you haven't already, to really make it pop!</span><br/><br/></>}
            Because inference costs money, we limit usage of free datacakes to 20 questions a week (from any user). A couple options if you need more than that: <br />
          </Typography>
          <ol>
            <li>
              <Typography variant="body2" color={'#5B6B6F'} className='leading-5 mb-4'>
                Join our beta program <a href="https://www.datacakes.ai/contact">here</a>
              </Typography>
            </li>
          </ol>
          <Typography variant="body2" color={'#5B6B6F'} className='leading-5 mb-4'>
            OR
          </Typography>
          <ol>
            <li>
              <Typography variant="body2" color={'#5B6B6F'} className='leading-5 mb-4'>
                Submit your datacake/dashboard in our Discord to be showcased in our public gallery
              </Typography>
            </li>
          </ol>
        </Box>
        <Box className='bg-[#f0f2f2] flex-1 flex flex-row min-h-[55px] mr-6'>
          <Box className='flex-1 px-3 py-1'>
            <Typography fontSize={12} color={'#5B6B6F'}>URL</Typography>
            <Typography>{url}</Typography>
          </Box>
          <CopyToClipboard text={url}
            onCopy={() => success('URL Copied!')}>
            <Button className='copyUrlButton'>
              <img src={COPY} />
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>
    </Modal>
  )
}

export default ShareModal
