import { createBrowserRouter } from 'react-router-dom'
import AskCubie from '../../components/AskCubie/AskCubie'
import { APP_ROOT_PATH } from '../config/main'
import Dashboard from '../../components/Dashboard/Dashboard'
import DataMarket from '../../components/DataMarket/DataMarket'

const mainRouter = createBrowserRouter(
  [
    {
      path: '/',
      element: <Dashboard />,
      // errorElement: <Error />
    },
    {
      path: '/ask',
      element: <AskCubie />,
      // errorElement: <Error />
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
      // errorElement: <Error />,
    },
    {
      path: '/datamarket',
      element: <DataMarket />,
      // errorElement: <Error />
    }
  ],
  { basename: APP_ROOT_PATH },
)

export default mainRouter
