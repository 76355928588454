import { Box, TextField, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { makeStyles } from '../../core/utils/theme'
import useSettings from '../../hooks/useSettings'
import ButtonLink from './ButtonLink'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import { srcSvcGet, srcSvcPost } from '../../core/services/source_service'
import ContentModal from '../Modals/ContentModal'
import loadinggif from '../../assets/loading.gif'
import { success } from '../../core/services/alerts'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

const CakeInstructions: FC = observer(() => {
  const { classes } = useStyles()
  const app = useApp()
  const settings = useSettings()
  const [loadStatus, setLoadStatus] = useState<string>("unloaded")
  const [instructionsAnalysis, setInstructionsAnalysis] = useState<string>('')
  const [instructionsVisualization, setInstructionsVisualization] = useState<string>('')

  useEffect(() => {
    const fetchInstructions = async () => {
      if (app.cakeId){
        setLoadStatus("loading")
        const instr = await srcSvcGet('/instructions', {cake_id:app.cakeId})
        setInstructionsAnalysis(instr.analysis)
        setInstructionsVisualization(instr.visualization)
        setLoadStatus("loaded")
      }
    }
    if (settings.isCakeInstructionsModalOpen && loadStatus=="unloaded") {
      setInstructionsAnalysis('')
      setInstructionsVisualization('')
      fetchInstructions()
    }
  }, [settings.isCakeInstructionsModalOpen, instructionsAnalysis, instructionsVisualization, loadStatus])

  const handleSubmit = async () => {
    setLoadStatus("loading")
    const result = await srcSvcPost('/instructions', {cake_id: app.cakeId, instructions: {analysis: instructionsAnalysis, visualization: instructionsVisualization}})
    setLoadStatus("loaded")
    console.log('post to /instructions result', result)
    if (result) {
      settings.setIsCakeInstructionsModalOpen(false)
      setLoadStatus('unloaded')
      success("Success.")
    }
  }

  return (
    <Box className={`${classes.root} flex flex-col gap-1`}>
      <Typography variant="body1" className="font-300 mb-2">
        Help Cubie understand your data...
        {loadStatus != "loaded" &&
          <img src={loadinggif}style={{display:'inline', height:'20px', margin:'0 0 2px 8px'}} />
        }
      </Typography>
        <TextField
          multiline
          rows={4}
          value={ instructionsAnalysis }
          onChange={(e)=>{setInstructionsAnalysis(e.target.value)}}
        ></TextField>
        <br/>
      <Typography variant="body1" className="font-300 mb-2">
        Tell Cubie how you like your visualizations...
        {loadStatus != "loaded" &&
          <img src={loadinggif}style={{display:'inline', height:'20px', margin:'0 0 2px 8px'}} />
        }
      </Typography>
        <TextField
          multiline
          rows={4}
          value={ instructionsVisualization }
          onChange={(e)=>{
            setInstructionsVisualization(e.target.value)
          }}
          onKeyUp={(e)=>{
            if (e.key == 'Enter' && (e.ctrlKey || e.metaKey))
              handleSubmit()
          }}
        ></TextField>

      <Box className="justify-end text-right mt-4">
        <ButtonLink variant='enclosed' onClick={handleSubmit}>Submit</ButtonLink>
      </Box>
    </Box>
  )
})

interface IProps {}
const CakeInstructionsModal: FC<IProps> = observer(() => {
  const settings = useSettings()

  const handleClose = () => {
    settings.setIsCakeInstructionsModalOpen(false)
  }

  return (
    <>
      <ContentModal
        open={settings.isCakeInstructionsModalOpen}
        title="Descriptions"
        onClose={handleClose}
      >
        <CakeInstructions />
      </ContentModal>
    </>
  )
})


export default CakeInstructionsModal
