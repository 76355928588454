import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useEffect, useState, MouseEvent } from 'react'
// import { default as ReactModal } from 'react-modal'
import { /*APP_NAME,*/ CONNECT_URL } from '../../core/config/main'
// import { datasets } from '../../../core/utils/constant'
import { makeStyles } from '../../core/utils/theme'
import { getDataServiceIcon } from '../../core/utils/main'
import useSettings from '../../hooks/useSettings'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { IDataSource } from '../../core/types/source_service/IDataSource'
import Loader from '../Misc/Loader'
import useUser from '../../hooks/useUser'
import Modal from 'react-modal'
import CLOSE from '../../assets/Svgs/navigation/close_24px.svg'


const useStyles = makeStyles()((theme: any) => ({
  root: {
    background: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('md')]: {
      padding: '20px 0px',
      // height: '90%',
      overflow: 'auto',
    },
    minHeight:'600px'
  },
  title: {
    color: theme.palette.text.disabled,

    '& > span': {
      color: '#FA589D',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',

      '& > span': {
        fontSize: '2rem',
      },
    },
  },
  datasets: {
    marginTop: '1.8rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > p': {
      color: theme.palette.text.disabled,
      marginBottom: '0.8rem',
      textAlign: 'center',
    },
  },
  datasetContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '2rem',
  },
  dataset: {
    cursor: 'pointer',
    maxWidth: '96px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '72px',
    },
  },
  datasetIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26px',
    height: '26px',
    background: '#EFEFEF',
  },
  datasetTitle: {
    marginTop: '0.5rem',
    color: theme.palette.text.disabled,
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
  connect_own_src: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > p': {
      color: theme.palette.text.disabled,
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .MuiIconButton-root img': {
      width: '26px',
      height: '26px',
    },
  },
}))

const DataServices: FC = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const settings = useSettings()
  const user = useUser()

  const handleSelectService = (svc: string) => {
    settings.setIsDataSourcesModalOpen(false)
    settings.setIsDataSourceManagerModalOpen(true, svc)
  }

  return (
    <>
      <Box className="mb-2">
        <Typography variant="subtitle1" className="mb-0 font-bold">
          Attach a data source of your own:
        </Typography>            
      </Box>
      <Box className={classes.connect_own_src}>
        <Grid container columns={5} spacing={1} className="flex justify-center">
          <Grid item className="mb-2">
            <DataServiceButton source='csv' label='CSV File' onClick={() => handleSelectService('csv')} />
          </Grid>
          <Grid item >
            <DataServiceButton source='bigquery' label='BigQuery' onClick={() => {window.location.href=CONNECT_URL('bigquery')}} />
          </Grid>
          <Grid item className="relative">
            <DataServiceButton source='snowflake' label='Snowflake' onClick={() => {user.isInsider() ? handleSelectService('snowflake') : 'disabled'}} />
            <span className="absolute text-xs text-red-500 italic right-1 top-2">coming soon</span>
          </Grid>
          <Grid item className="relative">
            <DataServiceButton source='googleads' label='Google Ads' onClick={() => {user.isInsider() ? window.location.href=CONNECT_URL('googleads') : 'disabled'}}  />
            <span className="absolute text-xs text-red-500 italic right-1 top-2">coming soon</span>
          </Grid>
          <Grid item className="relative">
            <DataServiceButton source='googleanalytics' label='Google Analytics' onClick={() => {user.isInsider() ? window.location.href=CONNECT_URL('googleanalytics') : 'disabled'}}  />
            <span className="absolute text-xs text-red-500 italic right-1 top-2">coming soon</span>
          </Grid>
          <Grid item className="relative">
            <DataServiceButton source='meta' label='Meta Ads' onClick='disabled' />
            <span className="absolute text-xs text-red-500 italic right-1 top-2">coming soon</span>
          </Grid>
          {user.isInsider() && 
          <Grid item className="relative">
            <DataServiceButton source='datacakes' label='Datacakes' onClick={()=>{navigate('/datamarket')}} />
            <span className="absolute text-xs text-red-500 italic right-1 top-2">coming soon</span>
          </Grid>
          }
        </Grid>
      </Box>
    </>
  )
}

const srcDisplayMap = {
  'bigquery': 'BigQuery',
  'duckdb': 'CSV',
  'csv': 'CSV',
  'snowflake': 'Snowflake',
  'googleads': 'Google Ads',
  'googleanalytics': 'Google Analytics',
  'datacakes': 'Datacake',
  'datacake': 'Datacake'
}

const DataSources: FC = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  // const user = useUser()
  const deletable = ['snowflake','datacakes','datacake','bigquery', 'googleads', 'googleanalytics']
  const manageable = ['googleads', 'googleanalytics']
  // const app = useApp()

  const handleDelete = (src_id: string) => {
    settings.deleteDataSource(src_id)
  }
  const handleManage = (src: IDataSource) => {
    settings.dataSourceIdBeingManaged = src.id
    settings.setIsDataSourceManagerModalOpen(true, src.type)
  }

  return (
        <Box className={classes.connect_own_src}>
          <Box className='w-full'>
            <Typography variant="subtitle1" className="mb-3 mt-10 font-bold">
            {settings.dataSources.length > 0 ? "Your connected data sources:" : ""}
            </Typography>
          </Box>
          <Box className="w-[80%]">
            {settings.dataSources.map((src) => {
                const is_deletable = deletable.includes(src.type)
                const is_manageable = manageable.includes(src.type)
                return <div key={src.id} className="">
                  <FontAwesomeIcon
                    className={`pr-10 opacity-40 hover:opacity-100 z-20 ${is_deletable ? 'cursor-pointer' : 'invisible'}`}
                    icon={faXmark}
                    onClick={() => { if (is_deletable) handleDelete(src.id)}} />
                  <span key={src.id}>{srcDisplayMap[src.type as keyof object] || 'Other'} {src.identifier ? `: ${src.identifier}` : ''}</span>
                  { is_manageable && <span className="ml-5 text-xs blue cursor-pointer" onClick={()=>handleManage(src)}>manage</span> }
                </div>
            })}
          </Box>
        </Box>
  )
})

interface IDataServiceButton {
  source: string
  label: string
  destination?: string
  onClick?: string | ((e?:MouseEvent)=>void)
}
const DataServiceButton: FC<IDataServiceButton> = ({ source, label, onClick='disabled' }) => {
  const icon = getDataServiceIcon(source)
  const settings = useSettings()

  return (
    <Box className='hover:border-[#00ECFF] '>
      <Box className="border border-slate-700 hover:border-black px-2 py-2 cursor-pointer"
        onClick={() => {
            if (onClick == 'disabled') {
              return
            } else {
              settings.setIsDataSourcesModalOpen(false);
              (onClick as Function)()
              return
            }
          }}
      >
        <IconButton
          disableRipple
        >
          <img src={icon} className='mr-3' style={{height:'26px'}} />
          <Typography variant='button' color={'black'}>{label}</Typography>
        </IconButton>
      </Box>
    </Box>
  )
}

// const modalStyles: ReactModal.Styles = {
//   overlay: {
//     backdropFilter: 'blur(10px)',
//     zIndex: 10,
//     background: 'transparent',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     WebkitBackdropFilter: 'blur(10px)',
//   },
//   content: {
//     color: 'var(--qbd-color-a1)',
//     padding: 0,
//     width: '100%',
//     position: 'static',
//     border: 0,
//     backgroundColor: 'transparent',
//     maxWidth: '932px',
//     height: '80%',
//     display: 'flex',
//     alignItems: 'center',
//   },
// }

const DataSourcesModal: FC = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const user = useUser();

  const handleBakeCake = () => {
    settings.setIsDataSourcesModalOpen(false)
    settings.setIsCreateDatacakeModalOpen(true)
  }

  useEffect(() => {
    if (settings.isDataSourcesModalOpen) {
      setIsLoading(true)
      settings
        .loadDataSources()
        .then(() => {
          setIsLoading(false)
        })
        .catch((e) => {
          console.log('Error in loading data sources', e)
        })
    }
  }, [settings.isDataSourcesModalOpen])

  /*
  useEffect(() => {
    if (app.cakeId) {
      handleClose()
    }
  }, [app.cakeId])
  */

  // useEffect(() => {
  //     if settings.setIsDataSourcesModalOpen(true)
  // }

  return (
    /*
    <ContentModal
      style={modalStyles}
      open={settings.isDataSourcesModalOpen}
      title={'Data Sources'}
      showCloseButton={true}
      onClose={handleClose}
    >*/
    <Modal
      isOpen={settings.isDataSourcesModalOpen}
      onRequestClose={() => settings.setIsDataSourcesModalOpen(false)}
      contentLabel={"Data Sources"}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10 px-4'}
      className={`static w-full min-h-500px md:w-10/12 xl:w-[70%] bg-white border border-black relative z-10`}
    >
      <Button className="absolute right-0 py-2 px-2 md:py-4 md:px-4" onClick={() => settings.setIsDataSourcesModalOpen(false)}>
        <img src={CLOSE} />
      </Button>

      <Box className={`${classes.root} w-full md:max-w-[90%] lg:max-w-full m-0 md:m-auto px-8 py-8 flex flex-col`}>
        <Box className="grow">
          <Box className={'flex flex-col grow items-center '}><Typography variant="h6" className="mb-4">Data Sources</Typography></Box>
          <DataServices />
          {isLoading && <Loader className="w-[40px]"/>}
          {!isLoading && <Box className="grow"><DataSources /></Box>}
        </Box>
        <Box className="w-full flex justify-end cursor-pointer">
          {settings.dataSources.length > 0 && <span className='m-2 mr-4 cursor-pointer' onClick={handleBakeCake}>Bake a Datacake &gt;</span>}
        </Box>
      </Box>
    </Modal>
  )
})

export default DataSourcesModal
