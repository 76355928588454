import { FC, ReactNode } from 'react'
import { Box, Typography, Button, Stack } from '@mui/material'
import Modal from 'react-modal'
// import { error } from '../../../core/services/alerts'

interface IProps {
  open: boolean
  title: string
  description?: string
  onClose: () => void,
  onDelete: () => void
  type: "user" | "recipe",
  children?: ReactNode
}
const DeleteConfirmationModal: FC<IProps> = ({ open = false, onClose, title, description, onDelete, type = 'recipe'  }) => {

  const handleDelete = async () => {
    // init the server call to delete.
    onDelete();
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      contentLabel={title}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={
        'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10'
      }
      className={`static max-h-full max-w-[480px] bg-white px-6 py-4 border border-black mx-4`}
    >
      <Box pt={1}>
        <Typography className='mb-4'>{description}</Typography>
        <Typography variant="body2" color={'#5B6B6F'} className='leading-5 mb-4'>
          {`
          Confirm deletion?
          `}
        </Typography>

        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            {`delete ${type === "recipe" ? 'recipe' : 'user'}`}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default DeleteConfirmationModal
