import { FC } from 'react'
import { IDataSample } from '../../core/types/code_service/IDataObject'
import DataTable from '../Misc/DataTable'
import ContentModal from './ContentModal'

interface IProps {
  head: IDataSample
  open: boolean
  title: string
  onClose: () => void,
  limit: number
}
const DataObjectHeadModal: FC<IProps> = ({ head, open = false, onClose, title, limit }) => {
  return (
    <ContentModal open={open} onClose={onClose} title={title}>
      <DataTable title={title} columns={head.columns} data={head.data} rowLimit={limit} />
    </ContentModal>
  )
}

export default DataObjectHeadModal
