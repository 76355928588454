import { FC } from 'react'
import { ITableLinkColumnLocal } from '../../../../core/types/source_service/ITableLinkColumnLocal'
// import { makeStyles, textPrimaryLightColor } from '../../../../../../core/utils/theme'
import { Autocomplete, FormControl, TextField } from '@mui/material'

const DEFAULT_VALUE = '--'

interface ITableAttributesDropdown {
  columns: ITableLinkColumnLocal[]
  onChange: (value: ITableLinkColumnLocal | null) => void
  // selectedColumn: ITableLinkColumnLocal | null
}

const TableAttributesDropdown: FC<ITableAttributesDropdown> = ({ columns, onChange }) => {
  // const determineKey = () => {
  //   return selectedColumn == null ? DEFAULT_VALUE : getKey(selectedColumn)
  // }

  const getKey = (column: ITableLinkColumnLocal) => {
    return column.table_id + '|' + column.column_name
  }
  const getLabel = (column: ITableLinkColumnLocal) => {
    return column.table_name + ' / ' + column.column_name
  }

  const getColumnByKey = (key: string) => {
    const [newTableId, newColumnName] = key.split('|')

    if (newTableId == null || newColumnName == null) {
      return null
    }

    const newColumn = columns.find(
      (column: ITableLinkColumnLocal) =>
        column.table_id === newTableId && column.column_name === newColumnName,
    )

    if (newColumn == null) {
      return null
    }

    return newColumn
  }

  return (
    <FormControl variant='filled' size="small" className='w-full'>
      <Autocomplete
        id="join_column"
        sx={{ 
          width: '100%',
          '& .MuiAutocomplete-popper': {
            backgroundColor: 'white',
          }
        }}
        options={columns}
        autoHighlight
        getOptionLabel={(option) => getLabel(option)}
        onChange={(_e: any, newValue: ITableLinkColumnLocal | null) => {
          if (newValue == null) {
            return
          }

          const val = getKey(newValue);
          if (val === DEFAULT_VALUE) {
            onChange(null)
            return
          }

          const newColumn = getColumnByKey(val)

          onChange(newColumn)
        }}
        renderInput={(params) => {
          console.log(params);
          return (
            <TextField {...params} placeholder="Click or type to select" />
          )
        }}
        renderOption={(props, option) => {
          return (
            <span {...props} style={{ backgroundColor: 'white' }}>
              {getLabel(option)}
            </span>
          );
        }}
      />
    </FormControl>
  )
}
export default TableAttributesDropdown
