import logo from '../../assets/datacakesIcon.png'
import { MAIN_SITE_URL } from '../../core/config/main'
import { makeStyles } from '../../core/utils/theme'
import { FC } from 'react'

const useStyles = makeStyles()(() => ({
  logo: {
    height:'50px',
    width:'50px',
    borderRadius:'6px'
  }
}))

const Logo2: FC = () => {
  const { classes } = useStyles()

  return (
      <a href={MAIN_SITE_URL} target="_blank">
        <img loading="lazy" src={logo} alt="Datacakes Logo" className={classes.logo} />
      </a>
  )
}

export default Logo2